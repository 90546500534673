import { navigate, graphql, StaticQuery } from 'gatsby';
import React from 'react';

import phpScriptsUrl from '../../phpScriptsUrl';
import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import styles from '../sass/components/answer.module.scss';
import { getUserData } from '../utils/userData';
import getString from '../utils/get-string';

class Final extends React.Component {
  componentDidMount() {
    if (
      typeof window !== `undefined` &&
      (!this.props.location.state || !getUserData())
    ) {
      return navigate(`/lessons`)
    }

    this.sendEmailToUser({
      baseUrl: `${this.props.location.protocol}//${this.props.location.host}`,
      email: getUserData().email,
    })
  }

  formEncode(obj) {
    var str = []
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    return str.join('&')
  }

  sendEmailToUser = data => {
    window.fetch(`${phpScriptsUrl}certificate.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: this.formEncode(data),
    })
  }

  render() {
    if (typeof window !== `undefined` && !this.props.location.state) {
      return <Layout />
    }

    return (
      <Layout>
        <Seo
          title={getString(this.props.strings, "11254088")}
          description={getString(this.props.strings, "11220977")}
        />

        <div className={styles.answer}>
          <Page type="success">
            <Header closeUrl="/lessons">{getString(this.props.strings, "11254329")}</Header>
            <Content>
              <h1 className={`h2 text-transform-uppercase`}>
                {getString(this.props.strings, "11220977")}
              </h1>
              <div className="page-text-container">
                <div className={`h6 ${styles.answer__text}`}>
                  {getString(this.props.strings, "11220978")}
                </div>
              </div>
            </Content>
            <Footer>
              <FooterLinkNext to="/lessons">{getString(this.props.strings, "11220979")}</FooterLinkNext>
            </Footer>
          </Page>
        </div>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11254088", "11254329", "11220978", "11220979", "11220977"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Final {...data} {...props} />}
  />
)